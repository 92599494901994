<template>
  <Home/>
</template>

<script>
import Home from './views/Home.vue';
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
body{
  margin: 0px;
  border: 0px;
  padding: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
