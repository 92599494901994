<template>
  <div id="resume_cover">
    <object
      class="resume_embed"
      data="darshan_resume.pdf"
      width="800px" height="2100px"
    ></object>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  props: {},
};
</script>

<style>
#resume_cover {
  border: 1px solid black;
}
#resume_embed {
}
</style>